<template>
  <div
    class="px-1 text-sm border-b focus-within:border-gray-600 hover:border-gray-500"
    @keydown.esc.prevent="clearSearch"
  >
    <input
      v-model="searchTerm"
      type="text"
      class="p-0 w-48 text-sm leading-5 bg-transparent border-0 focus:ring-0 focus:outline-none"
      placeholder="Suche..."
    >
    <button
      class="px-1 text-gray-400 cursor-default focus:outline-none far fa-empty-set"
      :class="{ 'text-gray-600 hover:text-gray-800 cursor-pointer': searchTerm.length > 0 }"
      :disabled="searchTerm.length === 0"
      title="Suche zurücksetzen"
      @click.prevent.stop="clearSearch"
    />
  </div>
</template>

<script>
  import { debounce } from '@/helpers'

  export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: 'vSearchBox',
    props: {
      timeout: { type: Number, default: 200 },
      capture: { type: Boolean, default: false }
    },
    emits: ['search'],
    data () {
      return {
        searchTerm: ''
      }
    },
    watch: {
      searchTerm (newValue) {
        if (newValue.length === 0) return this.emitNow()
        this.emit()
      }
    },
    created () {
      this.emit = debounce(() => this.emitNow(), this.timeout)
    },
    mounted () {
      if (this.capture) window.addEventListener('keydown', this.captureHandler)
    },
    beforeUnmount () {
      if (this.capture) window.removeEventListener('keydown', this.captureHandler)
    },
    methods: {
      setFocus () {
        const el = this.$el.querySelector('input')
        el.focus()
        el.select()
      },
      setSearchTerm (newValue) {
        this.searchTerm = newValue
      },
      emitNow () {
        this.$emit('search', this.searchTerm)
      },
      clearSearch () {
        this.searchTerm = ''
        this.$nextTick(this.setFocus)
      },
      captureHandler (e) {
        if (e.keyCode !== 114 && !(e.ctrlKey && e.keyCode === 70)) return
        e.preventDefault()
        this.setFocus()
      }
    }
  }
</script>
